import React from "react";
import ImageComponent from "./ImageComponent";

function Sponsors() {
  return (
    <div>
      <div className="allSponsors">
        <a href="https://www.egk.ch/" target="_blank" rel="noreferrer">
          <ImageComponent
            src="images/sponsor-egk.jpg"
            alt="Hauptsponsor EGK"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.raiffeisen.ch/einsiedeln-ybrig"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-raiffeisen.png"
            alt="Sponsor Raiffeisen"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a href="https://www.genossame.ch/" target="_blank" rel="noreferrer">
          <ImageComponent
            src="images/sponsor-genossame_lachen.jpeg"
            alt="Sponsor Genossame Lachen"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a href="https://www.eyz.swiss/de" target="_blank" rel="noreferrer">
          <ImageComponent
            src="images/EYZ_Logo.png"
            alt="Sponsor EYZ"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.korporation-wollerau.ch/"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-korporation-wollerau.png"
            alt="Sponsor korpf"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>

        <a href="https://8808.ch/" target="_blank" rel="noreferrer">
          <ImageComponent
            src="images/sponsor-korpf.png"
            alt="Sponsor korpf"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.einsiedeln.ch/home"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-bezirk-einsiedeln.jpg"
            alt="Sponsor korpf"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a href="https://www.swisslos.ch/" target="_blank" rel="noreferrer">
          <ImageComponent
            src="images/sponsor-swisslos.jpg"
            alt="Sponsor Swisslos"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>

        <a
          href="https://www.swisscasinos.ch/pfaeffikon"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-casino_pfaeffikon.png"
            alt="Sponsor korpf"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.eyz.swiss/de/besuchen/einsiedeln-tourismus"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-ET.jpg"
            alt="Sponsor korpf"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.einsiedlerwelttheater.ch/home"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-Welltheater.png"
            alt="Sponsor Welttheater"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.genossame-yberg.ch/"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-GenossameYberg.png"
            alt="Sponsor Genossame Yberg"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.hotel-stgeorg.ch/de"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-hotelSTGeorg.jpg"
            alt="Sponsor Hotel St. Georg"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.hoch-ybrig.ch"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-hy.jpg"
            alt="Sponsor Hoch-Ybrig"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://www.steinauer.ch"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/Logo_Steinauer_AG.jpg"
            alt="Sponsor Steinauer AG"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
        <a
          href="https://sponser.ch/"
          target="_blank"
          rel="noreferrer"
        >
          <ImageComponent
            src="images/sponsor-sponser.png"
            alt="Sponsor Sponser Sport Food"
            hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
            className="sponsors"
            height={150}
            width={150}
          />
        </a>
      </div>
    </div>
  );
}

export default Sponsors;
