import React from "react";
import Nationaler from "./Components/Content/Nationaler";
import News from "./Components/Content/News";
import SPM from "./Components/Content/SPM";
import "./Content.css";

function Content() {
  return (
    <main className="main">
      <div class="titleContainer">
        <img src="images/ComboPic.png" className="Homepicture" alt="Titlepic" />
        <h1 className="centered">EINSIEDLER OL WEEKEND</h1>
      </div>
      <div className="content">
        <p>
          <br />
          Einsiedeln ist seit dem Nat.OL 2010 <em>die</em> Sprint-OL-Destination
          in der Schweiz. <br />
          In der Zwischenzeit wurde viel umgebaut, das ist nun ziemlich
          abgeschlossen. <br />
          Es fanden in den letzten Jahren keine OLs und Trainings mehr statt,
          wir liessen den schönen Ort ruhen, um bei guter Gelegenheit wieder
          etwas Grosses zu machen - eine SPM, die Sprint OL
          Schweizermeisterschaft mit der absoluten Top-Elite! <br />
          Die Sprint OL Schweizermeisterschaft (SPM) ist auch ein World Ranking
          Event (WRE).
          <img
            className="logosInText"
            src="/images/swissorienteering-logo.png"
            alt="Swissorienteering Logo"
          />
          <a
            href="https://eventor.orienteering.org/Events/Show/8066"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="logosInText"
              src="images/iof-logo.png"
              alt="IOF Logo"
            />
          </a>
          <a
            href="https://eventor.orienteering.org/Events/Show/8066"
            target="_blank"
            rel="noreferrer"
          >
            IOF Eventor
          </a>
          <br />
          <br />
          In der Umgebung von Einsiedeln hat es anspruchsvolle interessante
          Waldgebiete. Vom Wettkampfzentrum in Einsiedeln geht es mit einem
          Läufertransport in den schönen unteren Teil Hoch Ybrig bei Oberiberg.
          Dort führen wir einen Nat.OL durch (Mitteldistanz).
        </p>
      </div>
      <News />
      <section>
        <div className="header" id="headerNat">
          <h5>
            Samstag, 11. Mai 2024: 4. Nationaler OL Hoch Ybrig (Mitteldistanz)
          </h5>
        </div>
        <div className="olInfo">
          <Nationaler />
        </div>
      </section>
      <section>
        <div className="header" id="headerSpm">
          <h5>
            Sonntag, 12. Mai 2024: Schweizer Sprint Meisterschaft Einsiedeln
          </h5>
        </div>
        <div className="olInfo">
          <SPM />
        </div>
      </section>
    </main>
  );
}

export default Content;
