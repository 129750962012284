import React, { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";

export default function ImageComponent({ src, alt, hash, className, height, width }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
  });
  return (
    <>
      <div style={{ display: imageLoaded ? " none" : "inline" , flex : 1, className: {className} }}>
        <Blurhash
          hash={hash}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
      <img
        onLoad={() => setImageLoaded(true)}
        src={src}
        alt={alt}
        className={className}
        style={{ display: !imageLoaded ? " none" : "inline" }}
      />
    </>
  );
}
