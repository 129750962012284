import React from "react";
import ImageComponent from "./ImageComponent";

function SponsorsSlider() {
  return (
    <div className="container">
      <div id="slider" className="carousel slide" data-bs-ride="carousel">
        <ol className="carousel-indicators">
          <li
            data-bs-target="#slider"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></li>
          <li
            data-bs-target="#slider"
            data-bs-slide-to="1"
            aria-current="true"
            aria-label="Slide 2"
          ></li>
          <li
            data-bs-target="#slider"
            data-bs-slide-to="2"
            aria-current="true"
            aria-label="Slide 3"
          ></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="row">
              <div className="col-sm-6">
                <ImageComponent
                  src="images/sponsor-egk.jpg"
                  alt="Sponsor EGK"
                  hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
                  className="sliderImage"
                  height={150}
                  width={150}
                />
              </div>
              <div className="col-sm-6 sliderText">
                <div>
                  <h3>EGK</h3>
                  <p>Wenn deine Gesundheit keine halben Sachen mag.</p>
                  <a
                    href="https://www.egk.ch/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button type="button" class="btn btn-secondary">
                      weitere Infos
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row">
              <div className="col-sm-6">
                <ImageComponent
                  src="images/sponsor-raiffeisen.png"
                  alt="Sponsor Raiffeisen"
                  hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
                  className="raiffeisenLogo"
                  height={150}
                  width={150}
                />
              </div>
              <div className="col-sm-6 sliderText">
                <div>
                  <h3>Raiffeisen</h3>
                  <p>Gemeinsam können wir mehr erreichen.</p>
                  <a
                    href="https://www.raiffeisen.ch/einsiedeln-ybrig"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button type="button" class="btn btn-secondary">
                      weitere Infos
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row">
              <div className="col-sm-6">
                <ImageComponent
                  src="images/sponsor-swisslos.jpg"
                  alt="Sponsor swisslos"
                  hash="LEF?FNTA2^bc.lSZNYRk_2ouIUni"
                  className="raiffeisenLogo"
                  height={150}
                  width={150}
                />
              </div>
              <div className="col-sm-6 sliderText">
                <div>
                  <h3>Swisslos</h3>
                  <p>Mit uns gewinnt die Schweiz</p>
                  <a
                    href="https://www.swisslos.ch/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button type="button" class="btn btn-secondary">
                      weitere Infos
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SponsorsSlider;
