import React, { useEffect, useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleClick = () => setToggleMenu(!toggleMenu);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth );
    });
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", function () {});
  });
  return (
    <div className="test">
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light px-3 justify-content-center">
        {(toggleMenu || screenWidth > 430) && (
          <ul className="nav">
            <li className="nav-item list">
              <a
                className="link-dark nav-link"
                href="#News"
                onClick={handleClick}
              >
                News
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="link-dark nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="."
                role="button"
                aria-expanded="false"
              >
                4. Nat. OL (Mittel)
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="link-dark dropdown-item "
                    href="#Laufgebiet_Nat"
                    onClick={handleClick}
                  >
                    Laufgebiet
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Ausschreibung_Nat"
                    onClick={handleClick}
                  >
                    Ausschreibung
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Weisungen_Nat"
                    onClick={handleClick}
                  >
                    Weisungen
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Streckendaten_Nat"
                    onClick={handleClick}
                  >
                    Streckendaten
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Anmeldung_Nat"
                    onClick={handleClick}
                  >
                    Anmeldung
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="link-dark nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="."
                role="button"
                aria-expanded="false"
              >
                SPM 2024
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Laufgebiet_SPM"
                    onClick={handleClick}
                  >
                    Laufgebiet
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Ausschreibung_SPM"
                    onClick={handleClick}
                  >
                    Ausschreibung
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Weisungen_SPM"
                    onClick={handleClick}
                  >
                    Weisungen
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Streckendaten_SPM"
                    onClick={handleClick}
                  >
                    Streckendaten
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark dropdown-item"
                    href="#Anmeldung_SPM"
                    onClick={handleClick}
                  >
                    Anmeldung
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        )}
        <button className="btnNav" onClick={handleClick}>
          <i id="bar" className="fas fa-bars"></i>
        </button>
        <img
          src="/images/Logo_Einsiedeln_quer.jpg"
          alt="logo"
          className="navLogo"
        />
      </nav>
    </div>
  );
};

export default Navbar;
