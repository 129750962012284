import React from "react";

function News() {
  return (
    <div id="News">
      <h5 className="subheading">News</h5>
      <div className="content">
        <ul>
          <li>29.04.24: Weisungen verfügbar</li>
          <li>26.04.24: Streckendaten verfügbar</li>
          <li>03.03.24: Ausschreibung verfügbar</li>
          <li>
            24.10.23: Infos zu Sperrgebieten: Flanieren und Übernachtung
            erlaubt, betreten mit Karte/Handynutzung verboten :
            <a
              href="https://www.olg-galgenen.ch/index.php/galgener-news/286-einsiedler-ol-weekend-2024-sperrgebiete-betretungsverbot"
              target="_blank"
              rel="noreferrer"
            >
              Weiterlesen
            </a>
          </li>
          <li>
            20.10.23: Die SPM ist auch ein WRE.
            <img
              className="logosInText"
              src="/images/swissorienteering-logo.png"
              alt="Swissorienteering Logo"
            />
            <a
              href="https://eventor.orienteering.org/Events/Show/8066"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="logosInText"
                src="images/iof-logo.png"
                alt="IOF Logo"
              />
            </a>
            <a
              href="https://eventor.orienteering.org/Events/Show/8066"
              target="_blank"
              rel="noreferrer"
            >
              IOF Eventor
            </a>
          </li>
          <li>1.10.23: detaillierte Ausschreibung folgt im Januar</li>
          <li>
            1.8.23: Internetseite online
            <br />
            Laufleiter: Reto Müller / Information/Kontakt: Yvonne Steiner,
            y.steiner@bluewin.ch, 079 202 61 77
          </li>
        </ul>
        <p>
          Liveresults:
          <a
            href="https://results.picoevents.ch"
            target="_blank"
            rel="noreferrer"
          >
            https://results.picoevents.ch
          </a>
        <br />
          Allgemeines Fotoalbum:
          <a
            href="https://www.flickr.com/photos/94170778@N04/albums/72177720314387483"
            target="_blank"
            rel="noreferrer"
          >
            https://www.flickr.com/photos/94170778@N04/albums/72177720314387483
          </a>
        <br />
          Fotoalbum Samstag:
          <a
            href="https://www.flickr.com/photos/94170778@N04/albums/72177720316803976"
            target="_blank"
            rel="noreferrer"
          >
            https://www.flickr.com/photos/94170778@N04/albums/72177720316803976
          </a>
        <br />
          Fotoalbum Sonntag:
          <a
            href="https://www.flickr.com/photos/94170778@N04/albums/72177720316799850"
            target="_blank"
            rel="noreferrer"
          >
            https://www.flickr.com/photos/94170778@N04/albums/72177720316799850
          </a>
        </p>
      </div>
    </div>
  );
}

export default News;
