import React, { useRef } from "react";
import SponsorsSlider from "../SponsorsSlider";
import Sponsors from "../Sponsors";
import PhotoAlbum from "react-photo-album";

const photos = [
  {
    src: "images/Bild-1.jpg",
    width: 800,
    height: 600,
  },
  {
    src: "images/Bild-3.jpg",
    width: 800,
    height: 600,
  },
  {
    src: "images/Bild-5.jpg",
    width: 500,
    height: 715,
  },
  {
    src: "images/Bild-2.jpg",
    width: 800,
    height: 520,
  },
  {
    src: "images/Bild-6.jpg",
    width: 500,
    height: 700,
  },
  {
    src: "images/Bild-9.jpg",
    width: 800,
    height: 600,
  },
  {
    src: "images/Bild-4.jpg",
    width: 800,
    height: 600,
  },
  {
    src: "images/Bild-10.jpg",
    width: 800,
    height: 600,
  },
  {
    src: "images/Bild-7.jpg",
    width: 800,
    height: 600,
  },
];

function Nationaler() {
  return (
    <div>
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-offset="0"
        className="scrollspy-example"
        tabIndex="0"
      >
        <h5 className="subheading" id="Laufgebiet_Nat">
          Laufgebiet
        </h5>
        <div className="content">
          <img
            src="images/Hochybrig_OLKarte.jpg"
            alt="Hochybrig OL-Karte"
            data-bs-toggle="modal"
            data-bs-target="#imageHochybrig"
            className="w-100 pointer olMap"
          />
          <PhotoAlbum layout="rows" photos={photos} />
        </div>
        <div
          className="modal fade imgModal w-100"
          id="imageHochybrig"
          tabIndex="1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <img
                  src="images/Hochybrig_OLKarte.jpg"
                  alt="Hochybrig OL-Karte"
                  className="d-block w-100"
                />
              </div>
            </div>
          </div>
        </div>
        <h5 className="subheading" id="Ausschreibung_Nat">
          Ausschreibung
        </h5>
        <div className="content">
          <h6>Download Ausschriebung:</h6>
          <a
            href={require("./pdfs/EOW2024_Ausschreibung_final.pdf")}
            download="Aussschreibung_EOW_DE"
          >
            <button type="button" class="btn btn-outline-primary Ausschreibung">
              Deutsch
            </button>
          </a>
          <a
            href={require("./pdfs/EOW2024_Ausschreibung_final_en.pdf")}
            download="Aussschreibung_EOW_EN"
          >
            <button type="button" class="btn btn-outline-primary Ausschreibung">
              English
            </button>
          </a>
          <a
            href={require("./pdfs/EOW2024_Ausschreibung_final_fr.pdf")}
            download="Aussschreibung_EOW_FR"
          >
            <button type="button" class="btn btn-outline-primary Ausschreibung">
              Français
            </button>
          </a>
          <a
            href={require("./pdfs/EOW2024_Ausschreibung_final_it.pdf")}
            download="Aussschreibung_EOW_IT"
          >
            <button type="button" class="btn btn-outline-primary Ausschreibung">
              Italiano
            </button>
          </a>
        </div>

        {/*<table class="table">
          <tbody>
            <tr>
              <td>Wettkampfform</td>
              <td>Mitteldistanz Maximalzeit 120 Minuten</td>
            </tr>
            <tr>
              <td>Veranstalter</td> <td>OLG Galgenen</td>
            </tr>
            <tr>
              <td>Laufleitung</td> <td>Reto Müller, Einsiedeln </td>
            </tr>
            <tr>
              <td>Auskunft</td>
              <td>
                Yvonne Steiner-Wicki, Einsiedeln, Tel. 079 202 61 77,
                y.steiner@bluewin.ch
              </td>
            </tr>
            <tr>
              <td>Bahnlegung / Kontrolle</td>
              <td>Thomas Häne / Patrick Denzler</td>
            </tr>
            <tr>
              <td>TD / Wettkampfrichter </td>
              <td>André Wirz / Barbara Hüsler </td>
            </tr>
            <tr>
              <td>Wettkampfzentrum</td> <td> Schulhaus Brüel, Einsiedeln</td>
            </tr>
            <tr>
              <td>Karte</td>
              <td>
                Hoch-Ybrig, 1:10'000 bzw. 1:7'500 ab Kat. D/H50 sowie HAK/DAK{" "}
                <br />
                Eingedruckte Bahnen für alle Kategorien Kartografie: Beat Imhof,
                Stand Frühling 2024
              </td>
            </tr>
            <tr>
              <td>Sperrgebiete</td>
              <td>
                Siehe nähere Erläuterungen auf{" "}
                <a href="https://eow.olg-galgenen.ch/#News">
                  https://eow.olg-galgenen.ch/
                </a>
                , insbesondere auch die detaillierten Bestimmungen zu Aufenthalt
                und Übernachtungen in Einsiedeln.
              </td>
            </tr>
            <tr>
              <td>Gelände</td>
              <td>
                Schönes voralpines Gelände, welches sowohl läuferisch als auch
                kartentechnisch fordert. Mehrheitlich gut belaufbarer Wald
                wechselt sich mit wenigen offenen Passagen (Alpweiden) ab. Der
                Wald ist von halboffenen Gebieten und Sümpfen sowie Gebieten mit
                eingeschränkter Sicht durchgezogen. Fotos und die alte Karte auf
                <a href="https://eow.olg-galgenen.ch//#Laufgebiet_Nat">
                  https://eow.olg-galgenen.ch/
                </a>
              </td>
            </tr>
            <tr>
              <td>Kategorien</td>
              <td>
                <lu>
                  <li>
                    Alle nach WO vorgeschriebenen Leistungskategorien, inkl.
                    D/H10
                  </li>
                  <li>
                    Offen kurz (OK), Offen Mittel (OM), Offen Lang (OL) Wer in
                    den
                  </li>
                </lu>
                Leistungsund Offen-Kategorien starten will, muss zuerst den Lauf
                in der Leistungskategorie absolvieren. <br />
                Start in Gruppen nur in den Offen-Kategorien möglich.
              </td>
            </tr>
            <tr>
              <td>Startgeld</td>
              <td>
                <lu>
                  <li>Jahrgang 2008 und jünger Fr. 18.—</li>
                  <li>Jahrgang 2004 bis 2007 Fr. 24.—</li>
                  <li>Jahrgang 2003 und älter Fr. 38.—</li>
                  <li>Gruppen 2008 und jünger Fr. 20.—</li>
                  <li>übrige Fr. 26.—</li>
                  <li>zusätzliche Karte Fr. 3.—</li>
                  <li>Miete SI-Card Fr. 2.—</li>
                </lu>
                Erhöhtes Startgeld aufgrund Läufertransport
              </td>
            </tr>
            <tr>
              <td>Anti-Doping</td>
              <td>
                In den Kategorien HE/DE/H20/D20 darf nur starten, wer
                unterschriftlich bestätigt hat, dass er von den
                Doping-Bestimmungen Kenntnis genommen hat. Informationen und das
                entsprechende Formular können unter{" "}
                <a href="https://www.swiss-orienteering.ch/de/integritaet/uebersicht-antidoping.html">
                  https://www.swiss-orienteering.ch/de/integritaet/uebersicht-antidoping.html
                </a>
                ein gesehen bzw. heruntergeladen werden. Wir weisen zusätzlich
                darauf hin, dass Dopingkontrollen auch in allen anderen
                Kategorien möglich sind.
              </td>
            </tr>
            <tr>
              <td>Anmeldungen</td>
              <td>
                Online via 
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ol-events.ch/races/484"
            >www.ol-events.ch</a>
              </td>
            </tr>
            <tr>
              <td>Anmeldeschluss</td> <td>Montag, 29.04.2024 / 23.59 Uhr</td>
            </tr>
            <tr>
              <td>Nachmeldungen</td>
              <td>
                Nachmeldungen (sofern freie Startplätze vor handen) bis
                Donnerstag, 02.05.2024 möglich online unter{" "}
                <a href="https://modify.picoevents.ch">
                  https://modify.picoevents.ch
                </a>{" "}
                <br />
                Gebühr CHF 10.00 <br />
                Anmeldung Offen-Kategorien am Lauftag bis 13.30 Uhr ohne
                Zuschlag möglich
              </td>
            </tr>
            <tr>
              <td>Mutationen</td>
              <td>
                Möglich bis Freitag, 10.05.2024 / 18.00 Uhr (ohne D/H 16 - 20)
                online unter{" "}
                <a href="https://modify.picoevents.ch">
                  https://modify.picoevents.ch
                </a>{" "}
                <br />
                Gebühr CHF 10.00 <br />
                Änderung SI-Card kostenlos
              </td>
            </tr>
            <tr>
              <td>Startlisten</td>
              <td>
                Startlisten werden ca. 1 Woche vor dem Lauf auf{" "}
                <a href="https://swiss-orienteering.ch">
                  www.swiss-orienteering.ch
                </a>{" "}
                publiziert <br />
                Gleiche Startnummern für beide Tage
              </td>
            </tr>
            <tr>
              <td>Startzeiten</td>
              <td>
                12.00 bis ca. 16.00 Uhr / regionale Auslosung. <br />
                Ausnahme: Testlaufkategorien nach Vorgabe Swiss Orienteering
              </td>
            </tr>
            <tr>
              <td>Auswertung</td>
              <td>Sportident, das AIR+ System ist aktiviert</td>
            </tr>
            <tr>
              <td>Anreise</td>
              <td>
                Einsiedeln ist sehr gut mit dem Zug zu erreichen. Wir empfehlen
                die Anreise mit dem Zug! Ab Einsiedeln Bahnhof 15 Minuten zum
                WKZ. <br />
                Parkplätze in Einsiedeln signalisiert (ca. 15 – 25 Minuten zum
                WKZ). Gebühr CHF 5.00/Tag. Anfahrt nur via Biberbrugg möglich
                (Strasse Bennau - Schnabelsberg ist wegen Seifenkistenrennen
                gesperrt). <br />
                Sonntag: kein Befahren/Begehen des Dorfes, auch nicht via
                Eisenbahnstrasse
              </td>
            </tr>
            <tr>
              <td>Kinderhort</td>
              <td>
                Beim Schulhaus Brüel an beiden Tagen, ohne Altersbeschränkung.{" "}
                <br />
                Anmeldung via Formular bis Dienstag, 07.05.2024. <br />{" "}
                <a
                  href={require("./pdfs/Anmeldeform_KH_de.docx")}
                  download="Kinderhort_Anmeldung_DE"
                >
                  <button
                    type="button"
                    class="btn btn-outline-secondary Ausschreibung"
                  >
                    Deutsch
                  </button>
                </a>
                <a
                  href={require("./pdfs/Anmeldeform_KH_en.docx")}
                  download="Kinderhort_Anmeldung_EN"
                >
                  <button
                    type="button"
                    class="btn btn-outline-secondary Ausschreibung"
                  >
                    English
                  </button>
                </a>
                <a
                  href={require("./pdfs/Anmeldeform_KH_fr.docx")}
                  download="Kinderhort_Anmeldung_FR"
                >
                  <button
                    type="button"
                    class="btn btn-outline-secondary Ausschreibung"
                  >
                    Français
                  </button>
                </a>
                <a
                  href={require("./pdfs/Anmeldeform_KH_it.docx")}
                  download="Kinderhort_Anmeldung_IT"
                >
                  <button
                    type="button"
                    class="btn btn-outline-secondary Ausschreibung"
                  >
                    Italiano
                  </button>
                </a>
              </td>
            </tr>
            <tr>
              <td>Kinder-OL</td>
              <td>An beiden Tagen auf dem Schulhausareal </td>
            </tr>
            <tr>
              <td>Weg WKZ - Start </td>
              <td>
                Läufertransport Einsiedeln – Auslad (ca. 30 min), von dort
                Fussmarsch an den Start (ca. 15 min). <br />
                Allfällige Wartezeiten nicht berücksichtigt
              </td>
            </tr>
            <tr>
              <td>Weg Ziel - WKZ</td>
              <td>
                Fussmarsch zum Buseinlad und Zielverpflegung (ca. 10 min),
                anschliessend Bustransport zurück nach Einsiedeln
              </td>
            </tr>
            <tr>
              <td>Kleidertransport</td> <td> Vom Busauslad zum Buseinlad</td>
            </tr>
            <tr>
              <td>Rangliste</td>
              <td>
                Liveresults:{" "}
                <a href="https://results.picoevents.ch">
                  https://results.picoevents.ch
                </a>{" "}
                <br />
                Definitive Rangliste wird auf{" "}
                <a href="https://swiss-orienteering.ch">
                  www.swiss-orienteering.ch
                </a>{" "}
                publiziert.
              </td>
            </tr>
            <tr>
              <td>Festwirtschaft</td>
              <td>
                Reichhaltige Festwirtschaft an beiden Tagen im Wettkampfzentrum.
              </td>
            </tr>
            <tr>
              <td>Versicherung</td>
              <td>
                Ist Sache der Teilnehmer. Jegliche Haftung des Veranstalters
                wird soweit gesetzlich zulässig abgelehnt.
              </td>
            </tr>
            <tr>
              <td>Weisungen</td>
              <td>
                Spätestens am 02.05.2024 auf
                <a href="https://eow.olg-galgenen.ch/#Weisungen_Nat">
                  https://eow.olg-galgenen.ch/
                </a>
              </td>
            </tr>
            <tr>
              <td>Durchführung</td>
              <td>
                Bei extremen Wetterbedingungen wird bis spätestens 11.05.24,
                08:00 Uhr auf{" "}
                <a href="https://eow.olg-galgenen.ch">eow.olg-galgenen.ch</a>{" "}
                und im Forum auf{" "}
                <a href="https://swiss-orienteering.ch">
                  www.swiss-orienteering.ch
                </a>{" "}
                über eine allfällige Absage eines oder beider Läufe informiert.
              </td>
            </tr>
            <tr>
              <td>Medien</td>
              <td>Philipp Haene, 076 431 36 18, philipp.haene@hotmail.com </td>
            </tr>
            <tr>
              <td>Uebernachtungen</td>
              <td>
                Einsiedeln Tourismus,{" "}
                <a href="https://www.eyz.swiss">www.eyz.swiss</a>. Bitte bei
                Buchung angeben, dass Teilnehmer OL-Weekend! <br />
                Hotels in Einsiedeln sind erlaubt, müssen aber am Sonntag
                spätestens bis 08.15 Uhr via Hauptstraße – Klosterplatz
                verlassen werden.
              </td>
            </tr>

            <tr>
              <td>Camper</td>
              <td>
                offizielle Stellplätze auf dem Friedhofparkplatz Einsiedeln
                (WC-Anlage Friedhof tagsüber geöffnet und frei zugänglich).
                Aufenthalt max. 48 Stunden. Die Regeln sind zu befolgen. CHF
                25.-/24 h (inkl. Kurtaxen). Bezahlung mittels ParkingPay, Twint
                oder Park'n sleep.
              </td>
            </tr>

            <tr>
              <td>Rahmenprogramm</td>
              <td>
                Jungtierschau: Vis à vis des Wettkampfzentrums im
                Paracelsus-Park findet an beiden Tagen eine Jungtierschau mit
                Sreichelzoo statt. Ein Besuch mit Kleinkindern lohnt sich und
                ist erlaubt. <br />
                Welttheater: Proben fürs Einsiedler Welttheater auf dem
                Klosterplatz. Besuch kostenlos. <br />
                Weitere Attraktionen in der Region auf{" "}
                <a href="https://www.eyz.swiss">www.eyz.swiss</a>
              </td>
            </tr>

            <tr>
              <td>Datenschutzrichtlinien</td>
              <td>
                Es gelten die Richtlinien von Swiss Orienteering vom 24.08.21{" "}
                <br />
                Wer an einer OL-Veranstaltung teilnimmt, welche auf der
                Terminliste von Swiss Orienteering unter o-l.ch aufgeführt ist,
                akzeptiert, dass er in publizierten Start- und Ranglisten
                erscheint. Eine nachträgliche Entfernung des Namens oder auch
                der Zeit resp. des Ranges aus der Rangliste ist ausgeschlossen.
                Swiss Orienteering legt fest, wie lange die Start- und
                Ranglisten öffentlich einsehbar bleiben.
              </td>
            </tr>
          </tbody>
                </table>*/}
        <h5 className="subheading" id="Weisungen_Nat">
          Weisungen
        </h5>
        <div className="content">
          <h6>Download Weisungen:</h6>
          <a
            href={require("./pdfs/EOW2024_Weisungen.pdf")}
            download="Weisungen_DE"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              Deutsch
            </button>
          </a>
          <a
            href={require("./pdfs/EOW2024_Weisungen_EN.pdf")}
            download="Weisungen_EN"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              English
            </button>
          </a>
          <a
            href={require("./pdfs/EOW2024_Weisungen_FRA.pdf")}
            download="Weisungen_FRA"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              Français
            </button>
          </a>
          <a
            href={require("./pdfs/EOW2024_Weisungen_ITA.pdf")}
            download="Weisungen_IT"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              Italiano
            </button>
          </a>
        </div>
        <h5 className="subheading" id="Streckendaten_Nat">
          Streckendaten
        </h5>
        <a
          href={require("./pdfs/EOW2024_StreckendatenSamstag.pdf")}
          download="Streckendaten_4_Nationaler"
        >
          <button type="button" class="btn btn-outline-primary Ausschreibung">
            Download PDF
          </button>
        </a>
        <div className="content row">
          <div className="column">
            <table class="table mansData">
              <thead>
                <tr>
                  <th scope="col">Bahn</th>
                  <th scope="col">Länge</th>
                  <th scope="col">Steigung</th>
                  <th scope="col">Posten</th>
                  <th scope="col">Start</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">HE</th>
                  <td>5.0 km</td>
                  <td>240 m</td>
                  <td>20 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">HAL</th>
                  <td>4.0 km</td>
                  <td>190 m</td>
                  <td>16 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">HAM</th>
                  <td>2.8 km</td>
                  <td>150 m</td>
                  <td>12 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">HAK</th>
                  <td>2.3 km</td>
                  <td>110 m</td>
                  <td>10 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">HB</th>
                  <td>2.5 km</td>
                  <td>130 m</td>
                  <td>12 </td>
                  <td>1 </td>
                </tr>

                <tr>
                  <th scope="row">H35</th>
                  <td>3.5 km</td>
                  <td>170 m</td>
                  <td>16 </td>
                  <td>1 </td>
                </tr>

                <tr>
                  <th scope="row">H40</th>
                  <td>3.8 km</td>
                  <td>190 m</td>
                  <td>16 </td>
                  <td>1 </td>
                </tr>

                <tr>
                  <th scope="row">H45</th>
                  <td>3.8 km</td>
                  <td>180 m</td>
                  <td>16 </td>
                  <td>1 </td>
                </tr>

                <tr>
                  <th scope="row">H50</th>
                  <td>3.6 km</td>
                  <td>170 m</td>
                  <td>15 </td>
                  <td>1 </td>
                </tr>

                <tr>
                  <th scope="row">H55</th>
                  <td>3.6 km</td>
                  <td>150 m</td>
                  <td>15 </td>
                  <td>1 </td>
                </tr>

                <tr>
                  <th scope="row">H60</th>
                  <td>3.4 km</td>
                  <td>140 m</td>
                  <td>14 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">H65</th>
                  <td>2.9 km</td>
                  <td>130 m</td>
                  <td>13 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">H70</th>
                  <td>2.6 km</td>
                  <td>130 m</td>
                  <td>11 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">H75</th>
                  <td>2.2 km</td>
                  <td>120 m</td>
                  <td>11 </td>
                  <td>2 </td>
                </tr>
                <tr>
                  <th scope="row">H80</th>
                  <td>1.8 km</td>
                  <td>90 m</td>
                  <td>10 </td>
                  <td>2 </td>
                </tr>
                <tr>
                  <th scope="row">H85</th>
                  <td>1.7 km</td>
                  <td>60 m</td>
                  <td>9 </td>
                  <td>2 </td>
                </tr>
                <tr>
                  <th scope="row">H20</th>
                  <td>4.4 km</td>
                  <td>200 m</td>
                  <td>18 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">H18</th>
                  <td>4.4 km</td>
                  <td>200 m</td>
                  <td>18 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">H16</th>
                  <td>3.8 km</td>
                  <td>160 m</td>
                  <td>15 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">H14</th>
                  <td>2.8 km</td>
                  <td>150 m</td>
                  <td>14 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">H12</th>
                  <td>2.4 km</td>
                  <td>100 m</td>
                  <td>11 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">H10</th>
                  <td>2.0 km</td>
                  <td>70 m</td>
                  <td>10 </td>
                  <td>1 </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="column">
            <table class="table womansData">
              <thead>
                <tr>
                  <th scope="col">Bahn</th>
                  <th scope="col">Länge</th>
                  <th scope="col">Steigung</th>
                  <th scope="col">Posten</th>
                  <th scope="col">Start</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">DE</th>
                  <td>4.0 km</td>
                  <td>200 m</td>
                  <td>16 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">DAL</th>
                  <td>3.2 km</td>
                  <td>140 m</td>
                  <td>14 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">DAM</th>
                  <td>2.4 km</td>
                  <td>110 m</td>
                  <td>11 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">DAK</th>
                  <td>1.9 km</td>
                  <td>70 m</td>
                  <td>9 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">DB</th>
                  <td>2.3 km</td>
                  <td>80 m</td>
                  <td>10 </td>
                  <td>1 </td>
                </tr>

                <tr>
                  <th scope="row">D35</th>
                  <td>2.8 km</td>
                  <td>140 m</td>
                  <td>12 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D40</th>
                  <td>3.0 km</td>
                  <td>150 m</td>
                  <td>12 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D45</th>
                  <td>3.1 km</td>
                  <td>150 m</td>
                  <td>12 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D50</th>
                  <td>2.7 km</td>
                  <td>140 m</td>
                  <td>12 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D55</th>
                  <td>2.6 km</td>
                  <td>120 m</td>
                  <td>12 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D60</th>
                  <td>2.4 km</td>
                  <td>110 m</td>
                  <td>11 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D65</th>
                  <td>2.3 km</td>
                  <td>110 m</td>
                  <td>10 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D70</th>
                  <td>2.1 km</td>
                  <td>80 m</td>
                  <td>10 </td>
                  <td>2 </td>
                </tr>

                <tr>
                  <th scope="row">D75</th>
                  <td>1.9 km</td>
                  <td>70 m</td>
                  <td>10 </td>
                  <td>2 </td>
                </tr>
                <tr>
                  <th scope="row">D80</th>
                  <td>1.7 km</td>
                  <td>60 m</td>
                  <td>9 </td>
                  <td>2 </td>
                </tr>
                <tr>
                  <th scope="row">D20</th>
                  <td>3.7 km</td>
                  <td>160 m</td>
                  <td>15 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">D18</th>
                  <td>3.7 km</td>
                  <td>160 m</td>
                  <td>15 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">D16</th>
                  <td>3.0 km</td>
                  <td>120 m</td>
                  <td>14 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">D14</th>
                  <td>2.6 km</td>
                  <td>130 m</td>
                  <td>13 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">D12</th>
                  <td>2.3 km</td>
                  <td>100 m</td>
                  <td>11 </td>
                  <td>1 </td>
                </tr>
                <tr>
                  <th scope="row">D10</th>
                  <td>2.0 km</td>
                  <td>70 m</td>
                  <td>10 </td>
                  <td>1 </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table class="table womansData">
            <thead>
              <tr>
                <th scope="col">Bahn</th>
                <th scope="col">Länge</th>
                <th scope="col">Steigung</th>
                <th scope="col">Posten</th>
                <th scope="col">Start</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">OK</th>
                <td>2.0 km</td>
                <td>70 m</td>
                <td>10 </td>
                <td>1 </td>
              </tr>
              <tr>
                <th scope="row">OM</th>
                <td>2.3 km</td>
                <td>110 m</td>
                <td>11 </td>
                <td>1 </td>
              </tr>
              <tr>
                <th scope="row">OL</th>
                <td>2.9 km</td>
                <td>150 m</td>
                <td>13 </td>
                <td>1 </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5 className="subheading" id="Anmeldung_Nat">
          Anmeldung
        </h5>
        <div className="content">
          <p>
            Anmeldung offen auf{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ol-events.ch/races/484"
            >
              ol-events.ch
            </a>
          </p>
          <br />
          <h6>Kinderhort Anmeldeformular:</h6>
          <a
            href={require("./pdfs/Anmeldeform_KH_de.docx")}
            download="Kinderhort_Anmeldung_DE"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              Deutsch
            </button>
          </a>
          <a
            href={require("./pdfs/Anmeldeform_KH_en.docx")}
            download="Kinderhort_Anmeldung_EN"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              English
            </button>
          </a>
          <a
            href={require("./pdfs/Anmeldeform_KH_fr.docx")}
            download="Kinderhort_Anmeldung_FR"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              Français
            </button>
          </a>
          <a
            href={require("./pdfs/Anmeldeform_KH_it.docx")}
            download="Kinderhort_Anmeldung_IT"
          >
            <button
              type="button"
              class="btn btn-outline-secondary Ausschreibung"
            >
              Italiano
            </button>
          </a>
        </div>
      </div>
      <h5 className="subheading">Sponsoring</h5>

      <div className="content">
        <div className="mainSponsors">
          <div>
            <SponsorsSlider />
          </div>
          <div class="card border-light">
            <img
              class="card-img-top"
              src="images/EYZ_Logo.png"
              alt="EYZ logo"
            />
            <div class="card-body">
              <p class="card-text">
                Unterkünfte können unter folgendem link gefunden werden
              </p>
              <a
                href="https://www.eyz.swiss/de"
                target="_blank"
                rel="noreferrer"
              >
                <button type="button" class="btn btn-secondary">
                  Mehr erfahren
                </button>
              </a>
            </div>
          </div>
        </div>
        <Sponsors />
      </div>
    </div>
  );
}

export default Nationaler;
